import * as React from 'react';
import { IProductDaData } from './product-da.data';
import { IProductDaProps } from './product-da.props.autogenerated';

export interface IProductDaViewProps extends IProductDaProps<IProductDaData> {
    androidUrl: string;
    iosUrl: string;
    thumbnailUrl: string;
    url: string;
}

/**
 *
 * ProductDa component
 * @extends {React.PureComponent<IProductDaProps<IProductDaData>>}
 */
class ProductDa extends React.PureComponent<IProductDaProps<IProductDaData>> {
    public render(): JSX.Element | null {
        const {
            config,
            data: { productInfo }
        } = this.props;

        let AndroidUrl = '';
        let ThumbnailUrl = '';
        let IosUrl = '';
        const ProxyUrl = config.proxyUrl ? config.proxyUrl : '';
        let FinalUrl = '';

        console.log('Product Info', productInfo.result);

        if (productInfo.result && productInfo.result.ExtensionProperties) {
            let i = 0;
            while (i < productInfo.result.ExtensionProperties.length) {
                if (productInfo.result.ExtensionProperties[i].Key === 'ANDROIDURL') {
                    if (productInfo.result.ExtensionProperties[i].Value) {
                        AndroidUrl = JSON.stringify(productInfo.result.ExtensionProperties[i].Value?.StringValue).slice(1, -1);
                    }
                }
                if (productInfo.result.ExtensionProperties[i].Key === 'IOSMODELURL') {
                    if (productInfo.result.ExtensionProperties[i].Value) {
                        IosUrl = JSON.stringify(productInfo.result.ExtensionProperties[i].Value?.StringValue).slice(1, -1);
                    }
                }
                i++;
            }
            ThumbnailUrl = productInfo.result.PrimaryImageUrl !== undefined ? productInfo.result.PrimaryImageUrl : '';
        }

        if (AndroidUrl.length !== 0) {
            if (IosUrl.length !== 0) {
                FinalUrl = `${ProxyUrl}?ios=${IosUrl}&android=${AndroidUrl}`;
            } else {
                FinalUrl = `${ProxyUrl}?ios=${AndroidUrl}&android=${AndroidUrl}`;
            }
        }

        const ProductDaViewProps = {
            ...this.props,
            androidUrl: AndroidUrl,
            iosUrl: IosUrl,
            thumbnailUrl: ThumbnailUrl,
            url: FinalUrl
        };
        return this.props.renderView(ProductDaViewProps);
    }
}

export default ProductDa;
